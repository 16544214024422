<template>
  <form
    class="lookbook-upload"
    @submit.prevent
  >
    <file-upload
      class="lookbook-upload__control"
      :data="{ userId, uploaderId, token }"
      name="images"
      accept="image/jpeg,image/png,image/gif,image/webp"
      extensions="jpg,jpeg,png,gif,webp"
      :size="10 * 1024 * 1024"
      :maximum="10"
      :drop="true"
      :multiple="true"
      post-action="/api/upload/images"
      @input-file="onInputFile"
    >
      <span class="lookbook-upload__text">
        Drop or click to upload images<br>
        (max 10MB per file)
      </span>
    </file-upload>
  </form>
</template>

<script>
import FileUpload from 'vue-upload-component'

export default {
  components: {
    FileUpload
  },

  props: {
    userId: {
      type: String,
      required: true
    },
    uploaderId: {
      type: String,
      default: null
    },
    token: {
      type: String,
      default: null
    }
  },

  methods: {
    onInputFile (newFile, oldFile) {
      if (!oldFile && !newFile.error) {
        newFile.active = true
      } else if (newFile && oldFile && newFile.success && !oldFile.success) {
        this.$emit('upload', newFile.response)
      }
    }
  }
}
</script>

<style lang="scss">
.lookbook-upload {
  width: 200px;
  height: 200px;
  background-color: #eee;

  &__control {
    width: 100%;
    height: 100%;
    vertical-align: top;

    & > label {
      cursor: pointer;
    }
  }

  &__text {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 1.5;
    font-size: 14px;
  }
}
</style>
