<template>
  <div>
    <h5>{{ item.question.title }}</h5>
    <div>
      <div class="row">
        <div class="col-xs-12" v-if="item.answer.fit">
          <h6>How do you prefer dresses to fit your top half?</h6>
          <div>{{ item.answer.fit }}</div>
        </div>
        <div class="col-xs-12">
          <h6>What top silhouette do you prefer:</h6>
          <div>{{ item.answer.silhouette }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" v-if="item.answer.skirt">
          <h6>What shape of the skirt do you prefer?</h6>
          <div class="selected-images">
            <div class="selected-image element" v-for="image in item.answer.skirt"
                 :key="image.image" v-if="image"
            >
              <img class="selected-image__image" :src="'https://couturme.com' + (image.image)">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" v-if="item.answer.skirtLength">
          <h6>What are your preferred lengths of the skirt?</h6>
          <div class="selected-images">
            <div class="selected-image element" v-for="image in item.answer.skirtLength"
                 :key="image.image || image" v-if="image"
            >
              <img class="selected-image__image" :src="'https://couturme.com' + (image.image)">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" v-if="item.answer.sleeve">
          <h6>What sleeves styles that you prefer?</h6>
          <div class="selected-images">
            <div class="selected-image element" v-for="image in item.answer.sleeve"
                 :key="image.image || image" v-if="image"
            >
              <img class="selected-image__image" :src="'https://couturme.com' + (image.image)">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12" v-if="item.answer.neckline">
          <h6>What necklines do you prefer?</h6>
          <div class="selected-images">
            <div class="selected-image element" v-for="image in item.answer.neckline"
                 :key="image.image || image" v-if="image"
            >
              <img class="selected-image__image" :src="'https://couturme.com' + (image.image)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        question: {},
        answer: {}
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../../../css/animations/transitions';
  .selected-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .selected-image {
    &.element {
      max-width: 100px;
    }
    @include hover-zoom(1.5)
  }
</style>
