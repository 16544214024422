import adapter from '@/api/adapter'

export default Object.assign(Object.create(adapter), {
  getImagesInfo (token, userId) {
    return this.getData(`/upload/images/${userId}`, {
      params: { token }
    })
  },

  getImageComments (userId, imageId) {
    return this.getData(`/upload/image-comments/${userId}/${imageId}`)
  },

  saveImageComment (userId, imageId, authorId, authorName, commentText) {
    return this.post(
      '/upload/image-comments',
      {
        userId,
        imageId,
        authorId,
        authorName,
        commentText
      }
    ).then(result => result.data)
  },

  deleteImage (token, userId, imageId) {
    return this.post(
      '/upload/delete-image',
      {
        token,
        userId,
        imageId
      }
    ).then(result => result.data)
  }
})
