<template>
  <div>
    <h5>{{ item.question.title }}</h5>
    <div>
      <div v-if="item.answer.concealed">
        Conceal: {{ item.answer.concealed.join(', ') }}
      </div>
      <div v-if="item.answer.flaunted">
        Flaunt: {{ item.answer.flaunted.join(', ') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        question: {},
        answer: {}
      })
    }
  }
}
</script>
