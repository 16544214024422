<template>
  <div id="my_account">
    <div class="bg-cl-secondary py35 pl20">
      <div class="container">
        <breadcrumbs
          :routes="[{name: 'Homepage', route_link: '/'}]"
          active-route="My Account"
        />
        <h1>
          My Account
        </h1>
      </div>
    </div>

    <div class="container pt45 pb70">
      <div class="row px20 pt0">
        <div class="col-md-3 hidden-xs hidden-sm block">
          <nav class="static-menu h4 mb35">
            <ul class="m0 p0">
              <li class="mb20" v-for="(page, index) in navigation" :key="index" @click="notify(page.title)">
                <router-link :to="page.link" class="cl-accent">
                  {{ page.title }}
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="col-md-9">
          <component :is="this.$props.activeBlock" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../components/Breadcrumbs'
import MyProfile from '../components/blocks/MyAccount/MyProfile'
import MyShippingDetails from '../components/blocks/MyAccount/MyShippingDetails'
import MyNewsletter from '../components/blocks/MyAccount/MyNewsletter'
import MyOrders from '../components/blocks/MyAccount/MyOrders'
import MyOrder from '../components/blocks/MyAccount/MyOrder'
import MyReferralProgram from '../components/blocks/Referral/Settings'
import MyQuestionnaire from '../components/blocks/Questionnaire/AccountPage'
import DesignStudio from '../components/blocks/MyAccount/DesignStudio'

export default {
  data () {
    return {
      navigation: [
        { title: 'My profile', link: '/my-account' },
        { title: 'My shipping details', link: '/my-account/shipping-details' },
        { title: 'My newsletter', link: '/my-account/newsletter' },
        { title: 'My orders', link: '/my-account/orders' },
        { title: 'Design Studio', link: '/my-account/design-studio' }
        // { title: 'My referral program', link: '/my-account/referral' },
        // { title: 'My questionnaire', link: '/my-account/questionnaire' }
      ],
      returnEditMode: false
    }
  },
  components: {
    Breadcrumbs,
    MyProfile,
    MyShippingDetails,
    MyNewsletter,
    MyOrders,
    MyOrder,
    MyReferralProgram,
    MyQuestionnaire,
    DesignStudio
  },
  name: 'MyAccount',
  props: {
    activeBlock: {
      type: String,
      default: 'MyProfile'
    }
  },
  beforeMount () {
    this.$bus.$on('myAccount-before-updateUser', this.onBeforeUpdateUser)
    this.$bus.$on('myAccount-before-changePassword', this.onBeforeChangePassword)
  },
  destroyed () {
    this.$bus.$off('myAccount-before-updateUser', this.onBeforeUpdateUser)
    this.$bus.$off('myAccount-before-changePassword', this.onBeforeChangePassword)
  },
  methods: {
    onBeforeChangePassword (passwordData) {
      this.$store.dispatch('user/changePassword', passwordData)
    },
    onBeforeUpdateUser (updatedData) {
      if (updatedData) {
        try {
          this.$store.dispatch('user/update', { customer: updatedData })
        } catch (err) {
          this.$bus.$emit('myAccount-before-remainInEditMode', this.$props.activeBlock)
          console.error(err)
        }
      }
    },
    notify (title) {
      if (title === 'My loyalty card' || title === 'My product reviews') {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: 'This feature is not implemented yet! Please take a look at https://github.com/DivanteLtd/vue-storefront/issues for our Roadmap!',
          action1: { label: 'OK' }
        })
      }
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || 'My Account',
      meta: this.$route.meta.description ? [{ vmid: 'description', description: this.$route.meta.description }] : []
    }
  }
}
</script>

<style lang="scss">
@import '../css/base/text';
$color-tertiary: color(tertiary);

.static-menu {
  ul {
    list-style: none;
  }

  a {
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-tertiary;
    }

    &:hover,
    &.router-link-exact-active {
      &:after {
        opacity: 0;
      }
    }
  }
}
</style>
