<template>
  <div class="mb35">
    <!-- My order header -->
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6">
        <h3 class="m0 mb5">
          Order #{{ order.order_id }}
          <span class="brdr-1 brdr-cl-bg-secondary py5 px10 ml20 sans-serif fs-medium-small weight-400 cl-secondary">
            {{ order.status || 'placed' | capitalize }}
          </span>
        </h3>
      </div>
    </div>
    <!-- My order body -->
    <div class="row fs16 mb20">
      <div class="col-xs-12 h4">
        <p v-if="order.created_at">{{ order.created_at | date('MMMM D, YYYY') }}</p>
        <!--<p class="mt35">
          <a href="#" class="underline" @click.prevent="remakeOrder(skipGrouped(order.products))">Remake order</a>
        </p>-->
      </div>
    </div>
    <div class="row fs16 mb35">
      <div class="col-xs-12 h4">
        <h4>Items ordered</h4>
        <table class="brdr-1 brdr-cl-bg-secondary">
          <thead>
            <tr>
              <th class="serif lh20">
                Product Name
              </th>
              <th class="serif lh20">
                SKU
              </th>
              <th class="serif lh20">
                Price
              </th>
              <th class="serif lh20">
                Qty
              </th>
              <th class="serif lh20">
                Subtotal
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="brdr-top-1 brdr-cl-bg-secondary" v-for="item in skipGrouped(order.products)" :key="item.sku">
              <td class="fs-medium lh25" :data-th="'Product Name'">
                {{ item.item.name }}
              </td>
              <td class="fs-medium lh25" :data-th="'SKU'">
                {{ item.sku }}
              </td>
              <td class="fs-medium lh25" :data-th="'Price'">
                {{ item.price | price }}
              </td>
              <td class="fs-medium lh25 align-right" :data-th="'Qty'">
                {{ item.qty }}
              </td>
              <td class="fs-medium lh25" :data-th="'Subtotal'">
                {{ item.subtotal | price }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="brdr-top-1 brdr-cl-bg-secondary">
              <td colspan="4" class="align-right">
                Subtotal
              </td>
              <td>{{ total | price }}</td>
            </tr>
            <tr>
              <td colspan="4" class="align-right">
                Shipping
              </td>
              <td>{{ order.shipping_amount || 0 | price }}</td>
            </tr>
            <tr>
              <td colspan="4" class="align-right">
                Tax
              </td>
              <td>{{ order.tax_amount + order.discount_tax_compensation_amount || 0 | price }}</td>
            </tr>
            <tr v-if="order.discount_amount">
              <td colspan="4" class="align-right">
                Discount
              </td>
              <td>{{ order.discount_amount || 0 | price }}</td>
            </tr>
            <tr>
              <td colspan="4" class="align-right">
                Grand total
              </td>
              <td>{{ grandTotal | price }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="row fs16 mb35">
      <div class="col-xs-12 h4">
        <h4>Order informations</h4>
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <h5>Shipping address</h5>
            <address>
              <p>{{ shippingAddress.firstname }} {{ shippingAddress.lastname }}</p>
              <p>{{ shippingAddress.street[0] }} {{ shippingAddress.street[1] }}</p>
              <p>{{ shippingAddress.postcode }} {{ shippingAddress.city }}</p>
              <p>{{ shippingAddress.country }}</p>
            </address>
          </div>
          <div class="col-sm-6 col-md-3">
            <h5>Shipping method</h5>
            <p>{{ shippingMethod }}</p>
          </div>
          <div class="col-sm-6 col-md-3">
            <h5>Billing address</h5>
            <address>
              <p>{{ billingAddress.firstname }} {{ billingAddress.lastname }}</p>
              <p>{{ billingAddress.street[0] }} {{ billingAddress.street[1] }}</p>
              <p>{{ billingAddress.postcode }} {{ billingAddress.city }}</p>
              <p>{{ billingAddress.country }}</p>
            </address>
          </div>
          <div class="col-sm-6 col-md-3">
            <h5>Payment method</h5>
            <p>{{ paymentMethod }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyOrder',
  computed: {
    ordersHistory () {
      return this.$store.state.user.orders_history ? this.$store.state.user.orders_history.items : []
    },
    order () {
      return this.ordersHistory.find(order => {
        return order.order_id === this.$route.params.orderId
      }, (this))
    },
    paymentMethod () {
      return this.order ? this.order.addressInformation.payment_method_code : 'PayPal'
    },
    shippingMethod () {
      return this.order ? this.order.addressInformation.shipping_method_code : 'DPD'
    },
    billingAddress () {
      return this.order.addressInformation.billingAddress
    },
    shippingAddress () {
      return this.order.addressInformation.shippingAddress
    },
    total () {
      if (this.order.total) return this.order.total
      return this.order.products.reduce((total, p) => total + +p.item.price * p.qty, 0)
    },
    grandTotal () {
      if (this.order.grand_total) return this.order.grand_total
      return this.order.products.reduce((total, p) => total + +p.item.price * p.qty, 0) + (this.order.shipping_amount || 0) + (this.order.tax_amount || 0)
    }
  },
  methods: {
    remakeOrder (items) {
      items.forEach(item => {
        this.$store.dispatch('product/single', { options: { sku: item.sku }, setCurrentProduct: false, selectDefaultVariant: false }).then((product) => {
          product.qty = item.qty_ordered
          this.$store.dispatch('cart/addItem', { productToAdd: product }).then(() => { })
        })
      })
    },
    skipGrouped (items) {
      return items.filter((item) => {
        return !item.parent_item_id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$color-tertiary: color(tertiary);
$color-white-smoke: color(white-smoke);

table {
  border-collapse: collapse;
  width: 100%;

  @media (max-width: 767px) {
    border-top: none;
  }

  th, td {
    text-align: left;
    padding: 20px;

    &.align-right {
      text-align: right;

      @media (max-width: 767px) {
        text-align: left;
      }

    }

    @media (max-width: 1199px) {
      padding: 10px;
    }

  }

  thead {
    @media (max-width: 767px) {
      display: none;
    }
  }

  tbody {

    tr {
      @media (max-width: 767px) {
        display: block
      }

      &:nth-child(even) {
        td {
          background-color: $color-white-smoke;
        }
      }

    }

    td {
      @media (max-width: 767px) {
        display: block;
        text-align: left;
        padding: 10px 20px;
        &:before {
          content: attr(data-th) ': ';
          font-weight: 700;
        }
      }

      &:first-child {
        @media (max-width: 767px) {
          padding: 20px 20px 10px 20px;
        }
      }

      &:last-child {
        @media (max-width: 767px) {
          padding: 10px 20px 20px 20px;
        }
      }
    }

  }

  tfoot {

    tr {
      @media (max-width: 767px) {
        display: block
      }

      &:last-child {
        td:last-child {
         padding-bottom: 20px
        }
      }

    }

    td {
      @media (max-width: 767px) {
        display: block
      }

      &:first-child {
        @media (max-width: 767px) {
          font-weight: 700;
          padding: 20px 20px 5px 20px;
        }
      }

      &:last-child {
        @media (max-width: 767px) {
          padding: 5px 20px 0 20px;
        }
      }

    }

  }

  i {
    vertical-align: middle;
  }

}

a {
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-tertiary;
  }

  &:hover {
    &:after {
      opacity: 0;
    }
  }
}

address {
  font-style: normal;
}

</style>
