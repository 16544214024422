<template>
  <article class="mb35">
    <header class="row mb15">
      <div class="col-xs-16 col-sm-8">
        <h3 class="m0 mb5">
          Design Studio
        </h3>
        <small>Add your own inspiration images to help us create a gown of your dreams</small>
      </div>
    </header>
    <user-lookbook
      :images="images"
      :user-id="userId"
      :uploader-id="userId"
      :token="null"
      @upload="onUploadImages"
      @click="onThumbClick"
      @delete="onImageDelete"
    />
    <modal
      class="lookbook-modal"
      name="lookbook-modal"
      width="1000"
      height="auto"
      scrollable
      @closed="onModalClose"
    >
      <section class="lookbook-modal__content">
        <div class="lookbook-modal__imagebox" :style="`background-image: url(${modalImageUrl})`"></div>
        <div class="lookbook-modal__comments">
          <div class="lookbook-modal__comments-box">
            <div class="lookbook-modal__comments-title">Comments</div>
            <ul class="lookbook-modal__comments-list">
              <li
                class="lookbook-modal__comments-item"
                v-for="(comment, index) in modalComments"
                :key="index"
              >
                <div>{{ comment.authorName }}</div>
                <div>{{ comment.text }}</div>
              </li>
            </ul>
          </div>
          <form class="lookbook-modal__comments-form" @submit.prevent="onCommentSubmit">
            <input
              class="lookbook-modal__comments-input"
              v-model="commentInputValue"
              type="text"
              :placeholder="commentInputPlaceholder"
            >
          </form>
        </div>
      </section>
    </modal>
  </article>
</template>

<script>
import apiUpload from '../../../api/upload'
import UserLookbook from 'Common/components/Lookbook/Lookbook'

export default {
  components: {
    UserLookbook
  },

  data: () => ({
    userId: null,
    images: [],
    modalImageId: null,
    modalImageUrl: null,
    modalComments: [],
    commentInputValue: ''
  }),

  computed: {
    commentInputPlaceholder () {
      return this.modalComments.length ? 'Write a comment...' : 'What do you like about this design?'
    }
  },

  created () {
    this.userId = this.$store.state.user.current._id
    apiUpload.getImagesInfo(null, this.userId).then(images => {
      this.images = images
    })
  },

  methods: {
    makeThumbUrl (imageId) {
      return `/api/upload/thumbs/${this.userId}/${imageId}`
    },

    makeImageUrl (imageId) {
      return `/api/upload/images/${this.userId}/${imageId}`
    },

    onUploadImages (images) {
      this.images = this.images.concat(images)
    },

    onThumbClick (imageId) {
      this.modalImageId = imageId
      this.modalImageUrl = this.makeImageUrl(imageId)
      apiUpload.getImageComments(this.userId, imageId).then(comments => {
        this.modalComments = comments
      })

      this.$modal.show('lookbook-modal')
    },

    onImageDelete (imageId) {
      apiUpload.deleteImage(null, this.userId, imageId).then(() => {
        this.images = this.images.filter(image => image._id !== imageId)
      })
    },

    onCommentSubmit () {
      let user = this.$store.state.user.current

      apiUpload.saveImageComment(
        this.userId,
        this.modalImageId,
        this.userId,
        `${user.firstname} ${user.lastname}`,
        this.commentInputValue
      ).then(comment => {
        this.modalComments.push(comment)
      })

      this.commentInputValue = ''
    },

    onModalClose () {
      this.modalImageUrl = null
      this.modalImageUrl = null
      this.modalComments = []
    }
  }
}
</script>

<style lang="scss">
.lookbook-modal {
  z-index: 10000;

  & .v--modal-box {
    padding: 0 !important;
    max-width: 100vw;
  }

  &__content {
    display: flex;
    width: 100%;
    height: 600px;
    max-height: 100vh;
    min-height: 300px;
    background-color: #fff;
  }

  &__imagebox {
    flex: 2 1 65%;
    box-sizing: border-box;
    border: 10px solid #eee;
    background: #fff 50% 50% no-repeat;
    background-size: contain;
  }

  &__comments {
    flex: 1 1 35%;
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
  }

  &__comments-box {
    flex: 1 1 auto;
    padding: 20px;
    overflow-y: auto;
  }

  &__comments-title {
    padding: 20px 0;
    font-size: 1.1em;
    color: #666;
  }

  &__comments-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__comments-item {
    border-bottom: 1px solid #eee;
    margin-bottom: 1em;
    padding-bottom: 1em;
  }

  &__comments-form {
    flex: 0 1 auto;
    background-color: #fff;
  }

  &__comments-input {
    box-sizing: border-box;
    border: 0;
    padding: 10px 20px;
    width: 100%;
    height: 50px;
    outline: none;
  }
}
</style>
