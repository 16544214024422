<template>
  <div>
    <h5>{{ item.question.title }}</h5>
    <div class="selected-images">
      <div class="selected-image" v-for="image in item.answer"
           :key="image.image" v-if="image" :class="[item.question.type]"
      >
        <img class="selected-image__image" :src="'https://couturme.com' + (image.image)">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        question: {},
        answer: {}
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../../../css/animations/transitions';
  .selected-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .selected-image {
    &.images {
      max-width: 30%;
    }
    &.colors {
      max-width: 100px;
    }
    &.fabrics {
      max-width: 150px;
    }
    @include hover-zoom(1.5)
  }
</style>
