<template>
  <section class="lookbook">
    <lookbook-upload
      class="lookbook__item"
      :user-id="userId"
      :uploader-id="null"
      :token="null"
      @upload="onUploadImages"
    />
    <lookbook-image
      class="lookbook__item -image"
      v-for="{ _id: id, commentsCount } in images"
      :key="id"
      :thumb-url="makeThumbUrl(id)"
      :comments-count="commentsCount"
      @click.native="onThumbClick(id)"
      @delete="onImageDelete(id)"
    />
    <modal
      :name="deleteImageModalName"
      width="350"
      height="auto"
      @closed="onDeleteImageModalClose"
    >
      <div class="lookbook__modal">
        <h3>Confirm image delete</h3>
        <div>
          <button
            class="lookbook__button"
            @click="onImageDeleteConfirm"
          >
            Delete
          </button>
          <button
            class="lookbook__button"
            @click="onImageDeleteCancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import LookbookImage from 'Common/components/Lookbook/Image'
import LookbookUpload from 'Common/components/Lookbook/Upload'

export default {
  components: {
    LookbookImage,
    LookbookUpload
  },

  props: {
    images: {
      type: Array,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    uploaderId: {
      type: String,
      default: null
    },
    token: {
      type: String,
      default: null
    }
  },
  
  data: () => ({
    deleteImageId: null,
    deleteImageModalName: 'lookbook-delete-confirm-modal'
  }),

  methods: {
    makeThumbUrl (imageId) {
      return `/api/upload/thumbs/${this.userId}/${imageId}`
    },
    
    onUploadImages (images) {
      this.$emit('upload', images)
    },

    onThumbClick (imageId) {
      this.$emit('click', imageId)
    },

    onImageDelete (imageId) {
      this.deleteImageId = imageId
      this.$modal.show(this.deleteImageModalName)
    },
    
    onImageDeleteConfirm () {
      if (this.deleteImageId) {
        this.$emit('delete', this.deleteImageId)
      }
      this.$modal.hide(this.deleteImageModalName)
    },

    onImageDeleteCancel () {
      this.$modal.hide(this.deleteImageModalName)
    },

    onDeleteImageModalClose () {
      this.deleteImageId = null
    }
  }
}
</script>

<style lang="scss">
.lookbook {
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin: 10px 20px 10px 0 !important;

    &.-image {
      border: 1px solid #eee;
    }
  }

  &__modal {
    box-sizing: border-box;
    padding: 2em 1em;
    width: 100%;
    text-align: center;
  }

  &__button {
    margin: 0 1em;
    border: 1px solid #999;
    border-radius: 4px;
    padding: 0.5em;
    background-color: transparent;
    cursor: pointer;
  }
}
</style>
