<template>
  <div>
    <h5>{{ item.question.title }}</h5>
    <div>
      <div class="">
        <h6 v-if="item.answer.skipMeasurements">
          I dont have a measurements on me right now
        </h6>
      </div>
      <div class="row" v-if="item.answer.height">
        <div class="col-xs-12">
          <div v-if="item.answer.height">
            <h6>How tall are you?</h6>
            <div>{{ item.answer.height }}</div>
          </div>
        </div>
      </div>
      <div class="row" v-if="item.answer.weight">
        <div class="col-xs-12">
          <h6>What is your weight?</h6>
          <div>{{ item.answer.weight }}</div>
        </div>
      </div>
      <div class="row" v-if="item.answer.height">
        <div class="col-xs-12">
          <h4>What sizes do you typically wear?</h4>
          <div v-if="item.answer.dresses_size">
            <h6>Dresses</h6>
            <div>{{ item.answer.dresses_size }}</div>
          </div>
          <div v-if="item.answer.knit_tops_size">
            <h6>Knit Tops</h6>
            <div>{{ item.answer.knit_tops_size }}</div>
          </div>
          <div v-if="item.answer.shirts_size">
            <h6>Shirts</h6>
            <div>{{ item.answer.shirts_size }}</div>
          </div>
          <div v-if="item.answer.skirts_size">
            <h6>Skirts</h6>
            <div>{{ item.answer.skirts_size }}</div>
          </div>
          <div v-if="item.answer.bra_size">
            <h6>Bra Size?</h6>
            <div>{{ item.answer.bra_size }}</div>
          </div>
        </div>
        <div class="col-xs-12">
          <h4>How would you characterize your proportions?</h4>
          <div v-if="item.answer.arms_proportions">
            <h6>Arms</h6>
            <div>{{ item.answer.arms_proportions }}</div>
          </div>
          <div v-if="item.answer.shoulders_proportions">
            <h6>Shoulders</h6>
            <div>{{ item.answer.shoulders_proportions }}</div>
          </div>
          <div v-if="item.answer.torso_proportions">
            <h6>Torso</h6>
            <div>{{ item.answer.torso_proportions }}</div>
          </div>
          <div v-if="item.answer.hips_proportions">
            <h6>Hips</h6>
            <div>{{ item.answer.hips_proportions }}</div>
          </div>
          <div v-if="item.answer.legs_proportions">
            <h6>Legs</h6>
            <div>{{ item.answer.legs_proportions }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 ">
          <div v-if="item.answer.tummy">
            <h6>Please describe your tummy relative to he rest of your body:</h6>
            <div>{{ item.answer.tummy }}</div>
          </div>
        </div>
        <div class="col-xs-12 ">
          <div v-if="item.answer.buttocks">
            <h6>Your buttocks:</h6>
            <div>{{ item.answer.buttocks }}</div>
          </div>
        </div>
      </div>
      <div class="row mt20" v-if="item.answer.body_picture">
        <div class="col-sx-12">
          <div class="">
            <span class="h5 ml10 bold">Figure Images:</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-4 image-upload" v-if="item.answer.body_picture.front">
          <h5 class="">
            Front
          </h5>
          <div class="uploaded-photo">
            <img class="uploaded-photo__image" :src="'https://couturme.com' + (item.answer.body_picture.front)" alt="">
          </div>
        </div>
        <div class="col-xs-12 col-md-4 image-upload" v-if="item.answer.body_picture.back">
          <h5 class="">
            Back
          </h5>
          <div class="uploaded-photo">
            <img class="uploaded-photo__image" :src="'https://couturme.com' + (item.answer.body_picture.back)" alt="">
          </div>
        </div>
        <div class="col-xs-12 col-md-4 image-upload" v-if="item.answer.body_picture.side">
          <h5 class="">
            Side
          </h5>
          <div class="uploaded-photo">
            <img class="uploaded-photo__image" :src="'https://couturme.com' + (item.answer.body_picture.side)" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        question: {},
        answer: {}
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../../../css/animations/transitions';
  .selected-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .selected-image {
    &.element {
      max-width: 100px;
    }
    @include hover-zoom(1.5)
  }
  .uploaded-image {
    max-width: 100%;
    @include hover-zoom(1.5)
  }
</style>
