<template>
  <div class="mb35">
    <!-- My shipping details header -->
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6" :class="{ 'cl-accent' : !isEdited }">
        <h3 class="m0 mb5">
          My shipping details
        </h3>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="lh30 flex end-md" v-if="!isEdited">
          <a href="#" class="cl-tertiary flex" @click.prevent="edit">
            <span class="pr5">
              Edit your shipping details
            </span>
            <i class="material-icons cl-tertiary">edit</i>
          </a>
        </div>
      </div>
    </div>

    <!-- My shipping details body (edit mode) -->
    <div class="row" v-if="isEdited">
      <template>
        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="first-name"
          autocomplete="given-name"
          :placeholder="`${'First name'} *`"
          v-model.trim="shippingDetails.firstName"
          @input="$v.shippingDetails.firstName.$touch()"
          :validations="[
            {
              condition: !$v.shippingDetails.firstName.required && $v.shippingDetails.firstName.$error,
              text: 'Field is required'
            },
            {
              condition: !$v.shippingDetails.firstName.minLength,
              text: 'Name must have at least 3 letters.'
            }
          ]"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="last-name"
          autocomplete="family-name"
          :placeholder="`${'Last name'} *`"
          v-model.trim="shippingDetails.lastName"
          @input="$v.shippingDetails.lastName.$touch()"
          :validation="{
            condition: !$v.shippingDetails.lastName.required && $v.shippingDetails.lastName.$error,
            text: 'Field is required'
          }"
        />

        <base-checkbox
          v-if="hasBillingAddress()"
          class="col-xs-12 mb25"
          id="addCompanyFilled"
          v-model="useCompanyAddress"
          @click="fillCompanyAddress"
        >
          {{ "Use my company's address details" }}
        </base-checkbox>

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="street-address"
          autocomplete="address-line1"
          :placeholder="`${'Street name'} *`"
          v-model.trim="shippingDetails.street"
          @input="$v.shippingDetails.street.$touch()"
          :validation="{
            condition: !$v.shippingDetails.street.required && $v.shippingDetails.street.$error,
            text: 'Field is required'
          }"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="apartment-number"
          autocomplete="address-line2"
          :placeholder="`${'House/Apartment number'} *`"
          v-model.trim="shippingDetails.house"
          @input="$v.shippingDetails.house.$touch()"
          :validation="{
            condition: !$v.shippingDetails.house.required && $v.shippingDetails.house.$error,
            text: 'Field is required'
          }"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="city"
          autocomplete="address-level2"
          :placeholder="`${'City'} *`"
          v-model.trim="shippingDetails.city"
          @input="$v.shippingDetails.city.$touch()"
          :validation="{
            condition: !$v.shippingDetails.city.required && $v.shippingDetails.city.$error,
            text: 'Field is required'
          }"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="state"
          autocomplete="address-level1"
          :placeholder="'State / Province'"
          v-model.trim="shippingDetails.region"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="zip-code"
          autocomplete="postal-code"
          :placeholder="`${'Zip code'} *`"
          v-model.trim="shippingDetails.postcode"
          @input="$v.shippingDetails.postcode.$touch()"
          :validations="[
            {
              condition: !$v.shippingDetails.postcode.required && $v.shippingDetails.postcode.$error,
              text: 'Field is required'
            },
            {
              condition: !$v.shippingDetails.postcode.minLength,
              text: 'Zip code must have at least 3 letters.'
            }
          ]"
        />

        <base-select
          class="col-xs-12 col-sm-6 mb25"
          name="countries"
          :options="countryOptions"
          :selected="shippingDetails.country"
          :placeholder="'Country *'"
          :validations="[
            {
              condition: $v.shippingDetails.country.$error && !$v.shippingDetails.country.required,
              text: 'Field is required'
            }
          ]"
          v-model="shippingDetails.country"
          autocomplete="country-name"
          @blur="$v.shippingDetails.country.$touch()"
          @change="$v.shippingDetails.country.$touch()"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="phone-number"
          autocomplete="tel"
          :placeholder="'Phone Number'"
          v-model.trim="shippingDetails.phone"
        />

        <div class="hidden-xs col-sm-6 mb25" />

        <div class="col-xs-12 col-sm-6">
          <button-full
            @click.native="updateDetails"
            :disabled="$v.$invalid"
          >
            Update my shipping details
          </button-full>
        </div>
        <div class="col-xs-12 col-sm-6 flex middle-xs py10">
          <a href="#" @click="exitSection" class="h4 cl-accent">
            Cancel
          </a>
        </div>
      </template>
    </div>

    <!-- My shipping details summary -->
    <div class="row fs16 mb35" v-else>
      <div class="col-xs-12 h4">
        <p>
          {{ shippingDetails.firstName }} {{ shippingDetails.lastName }}
        </p>
        <base-checkbox
          v-if="useCompanyAddress"
          class="col-xs-12 mb25"
          id="useCompanyAddressFilled"
          v-model="useCompanyAddress"
          disabled
        >
          {{ "Use my company's address details" }}
        </base-checkbox>
        <p class="mb25">
          {{ shippingDetails.company }}
        </p>
        <p class="mb25">
          {{ shippingDetails.street }}
          <span v-if="shippingDetails.house"> {{ shippingDetails.house }}</span>
        </p>
        <p class="mb25">
          {{ shippingDetails.city }} {{ shippingDetails.postcode }}
        </p>
        <p class="mb25">
          <span v-if="shippingDetails.region">{{ shippingDetails.region }}, </span>
          {{ getCountryName() }}
        </p>
        <div class="mb25">
          {{ shippingDetails.phone }}
          <tooltip v-if="shippingDetails.phone">
            Phone number may be needed by carrier
          </tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import Tooltip from '../../Tooltip'
import Countries from '../../../resource/i18n/countries.json'
import { EVENT_USER_PROFILE_LOADED } from '@/store/modules/user/store'

export default {
  name: 'MyShippingDetails',
  data () {
    return {
      shippingDetails: {
        firstName: '',
        lastName: '',
        street: '',
        house: '',
        city: '',
        postcode: '',
        region: '',
        country: '',
        phone: ''
      },
      countries: Countries,
      useCompanyAddress: false,
      currentUser: Object.assign({}, this.$store.state.user.current),
      isEdited: false,
      remainInEditMode: false
    }
  },
  beforeMount () {
    this.$bus.$on(EVENT_USER_PROFILE_LOADED, this.onLoggedIn)
    this.$bus.$on('myAccount-before-remainInEditMode', block => {
      if (block === 'MyShippingDetails') {
        this.remainInEditMode = true
      }
    })
  },
  beforeDestroy () {
    this.$bus.$off(EVENT_USER_PROFILE_LOADED, this.onLoggedIn)
    this.$bus.$off('myAccount-before-remainInEditMode')
  },
  mounted () {
    this.shippingDetails = this.getShippingDetails()
  },
  methods: {
    onLoggedIn () {
      this.currentUser = Object.assign({}, this.$store.state.user.current)
      this.shippingDetails = this.getShippingDetails()
    },
    edit () {
      this.isEdited = true
    },
    objectsEqual (a, b) {
      const aProps = Object.keys(a)
      const bProps = Object.keys(b)

      if (aProps.length !== bProps.length) {
        return false
      }

      for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i]
        if (!b.hasOwnProperty(propName)) {
          return false
        } else {
          if (a[propName] !== null && b[propName] !== null && a[propName] === 'object' && b[propName] === 'object') {
            if (!this.objectsEqual(a[propName], b[propName])) {
              return false
            }
          } else if (a[propName] !== b[propName]) {
            return false
          }
        }
      }
      return true
    },
    updateDetails () {
      let updatedShippingDetails
      if (!this.objectsEqual(this.shippingDetails, this.getShippingDetails())) {
        updatedShippingDetails = JSON.parse(JSON.stringify(this.$store.state.user.current))
        if (this.currentUser.hasOwnProperty('default_shipping')) {
          let index
          for (let i = 0; i < this.currentUser.addresses.length; i++) {
            if (this.currentUser.addresses[i].id === Number(this.currentUser.default_shipping)) {
              index = i
            }
          }
          if (index >= 0) {
            updatedShippingDetails.addresses[index].firstname = this.shippingDetails.firstName
            updatedShippingDetails.addresses[index].lastname = this.shippingDetails.lastName
            updatedShippingDetails.addresses[index].street = [this.shippingDetails.street, this.shippingDetails.house]
            updatedShippingDetails.addresses[index].city = this.shippingDetails.city
            updatedShippingDetails.addresses[index].region = {
              region: this.shippingDetails.region ? this.shippingDetails.region : null
            }
            updatedShippingDetails.addresses[index].country_id = this.shippingDetails.country
            updatedShippingDetails.addresses[index].postcode = this.shippingDetails.postcode
            updatedShippingDetails.addresses[index].telephone = this.shippingDetails.phone ? this.shippingDetails.phone : ''
          } else {
            updatedShippingDetails = null
          }
        } else {
          if (!updatedShippingDetails.addresses) {
            updatedShippingDetails.addresses = []
          }
          updatedShippingDetails.addresses = [{
            firstname: this.shippingDetails.firstName,
            lastname: this.shippingDetails.lastName,
            street: [this.shippingDetails.street, this.shippingDetails.house],
            city: this.shippingDetails.city,
            ...(this.shippingDetails.region ? { region: { region: this.shippingDetails.region } } : {}),
            country_id: this.shippingDetails.country,
            postcode: this.shippingDetails.postcode,
            ...(this.shippingDetails.phone ? { telephone: this.shippingDetails.phone } : {}),
            default_shipping: true
          }]
        }
      }
      this.exitSection(null, updatedShippingDetails)
    },
    exitSection (event, updatedShippingDetails) {
      this.$bus.$emit('myAccount-before-updateUser', updatedShippingDetails)
      if (!updatedShippingDetails) {
        this.shippingDetails = this.getShippingDetails()
        this.useCompanyAddress = false
        this.remainInEditMode = false
      }
      if (!this.remainInEditMode) {
        this.isEdited = false
      }
    },
    fillCompanyAddress () {
      this.useCompanyAddress = !this.useCompanyAddress
      if (this.useCompanyAddress) {
        let defaultAddress = this.currentUser.addresses.find(a => a.default_shipping)
        if (defaultAddress) {
          this.shippingDetails.firstName = defaultAddress.firstname
          this.shippingDetails.lastName = defaultAddress.lastname
          this.shippingDetails.street = defaultAddress.street[0]
          this.shippingDetails.house = defaultAddress.street[1]
          this.shippingDetails.city = defaultAddress.city
          this.shippingDetails.postcode = defaultAddress.postcode
          this.shippingDetails.region = defaultAddress.region.region ? defaultAddress.region.region : ''
          this.shippingDetails.country = defaultAddress.country_id
        }
      } else {
        this.shippingDetails = this.getShippingDetails()
      }
    },
    getShippingDetails () {
      this.currentUser = Object.assign({}, this.$store.state.user.current)
      if (this.currentUser) {
        if (this.currentUser && this.currentUser.hasOwnProperty('addresses') && this.currentUser.addresses.find(a => a.default_shipping)) {
          let defaultAddress = this.currentUser.addresses.find(a => a.default_shipping)
          if (defaultAddress) {
            return {
              firstName: defaultAddress.firstname,
              lastName: defaultAddress.lastname,
              street: defaultAddress.street[0],
              house: defaultAddress.street[1],
              city: defaultAddress.city,
              postcode: defaultAddress.postcode,
              region: defaultAddress.region.region ? defaultAddress.region.region : '',
              country: defaultAddress.country_id,
              phone: defaultAddress.hasOwnProperty('telephone') ? defaultAddress.telephone : ''
            }
          }
        } else {
          return {
            firstName: this.currentUser.firstname,
            lastName: this.currentUser.lastname,
            street: '',
            house: '',
            city: '',
            postcode: '',
            region: '',
            country: '',
            phone: ''
          }
        }
      } else {
        return {
          firstName: '',
          lastName: '',
          street: '',
          house: '',
          city: '',
          postcode: '',
          region: '',
          country: '',
          phone: ''
        }
      }
    },
    getCountryName () {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].code === this.shippingDetails.country) {
          return this.countries[i].name
        }
      }
      return ''
    },
    hasBillingAddress () {
      if (this.currentUser) {
        if (this.currentUser.hasOwnProperty('default_billing')) {
          return true
        }
      }
      return false
    }
  },
  components: {
    Tooltip
  },
  computed: {
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    }
  },
  validations: {
    shippingDetails: {
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required
      },
      country: {
        required
      },
      street: {
        required
      },
      house: {
        required
      },
      postcode: {
        required,
        minLength: minLength(3)
      },
      city: {
        required
      }
    }
  }
}
</script>
