<template>
  <div class="mb35">
    <!-- My orders header -->
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6">
        <h3 class="m0 mb5">
          My orders
        </h3>
      </div>
    </div>
    <!-- My orders body -->
    <div class="row">
      <div class="col-xs-12" v-show="!isHistoryEmpty">
        <table class="brdr-1 brdr-cl-bg-secondary">
          <thead>
            <tr>
              <th class="p20 serif lh20">
                Order ID
              </th>
              <th class="p20 serif lh20 hide-on-xs">
                Date and time
              </th>
              <th class="p20 serif lh20 hide-on-xs">
                Author
              </th>
              <th class="p20 serif lh20 hide-on-xs">
                Value
              </th>
              <th class="p20 serif lh20 hide-on-xs">
                Type
              </th>
              <th class="p20 serif lh20 hide-on-xs">
                Status
              </th>
              <th class="p20 serif lh20">
&nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="brdr-top-1 brdr-cl-bg-secondary" v-for="order in ordersHistory" :key="order.entity_id">
              <td class="fs-medium lh25">
                {{ order.order_id }}
              </td>
              <td class="fs-medium lh25 hide-on-xs">
                {{ order.created_at || Date.now() | date }}
              </td>
              <td class="fs-medium lh25 hide-on-xs">
                {{ order.customer_firstname }} {{ order.customer_lastname }}
              </td>
              <td class="fs-medium lh25 hide-on-xs">
                {{ grandTotal(order) | price }}
              </td>
              <td class="fs-medium lh25 hide-on-xs">
                Purchase
              </td>
              <td class="fs-medium lh25 hide-on-xs">
                {{ order.status || 'placed' | capitalize }}
              </td>
              <td class="fs-medium lh25">
                <span class="relative dropdown">
                  <i class="material-icons cl-secondary pointer">more_horiz</i>
                  <div class="dropdown-content bg-cl-primary align-left sans-serif lh20 weight-400 fs-medium-small py5">
                    <router-link class="no-underline block py10 px15" :to="`/my-account/orders/${order.order_id}`">
                      View order
                    </router-link>
                    <a href="#" class="no-underline block py10 px15" @click.prevent="remakeOrder(skipGrouped(order.items))">Remake order</a>
                  </div>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-xs-12 h4" v-show="isHistoryEmpty">
        <p>No orders yet</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserOrders',
  computed: {
    ordersHistory () {
      return this.$store.state.user.orders_history ? this.$store.state.user.orders_history.items : []
    },
    isHistoryEmpty () {
      return this.ordersHistory.length < 1
    }
  },
  created () {
    this.$store.dispatch('user/getOrdersHistory', {refresh: true})
  },
  methods: {
    reorder (products) {
      products.forEach(item => {
        this.$store.dispatch('product/single', { options: { sku: item.sku }, setCurrentProduct: false, selectDefaultVariant: false }).then((product) => {
          product.qty = item.qty_ordered
          this.$store.dispatch('cart/addItem', { productToAdd: product }).then(() => { })
        })
      })
    },
    skipGrouped (items) {
      return items.filter((item) => {
        return !item.parent_item_id
      })
    },
    grandTotal (order) {
      if (order.grand_total) return order.grand_total
      return order.products.reduce((total, p) => total + +p.item.price * p.qty, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../css/base/global_vars';
$color-icon-hover: color(secondary, $colors-background);

table {
  border-collapse: collapse;
  width: 100%;

  th, td {
    text-align: left;
    padding: 20px;

    @media (max-width: 1199px) {
      padding: 10px;
    }

    @media (max-width: 767px) {
      text-align: center;
    }

    &.hide-on-xs {

      @media (max-width: 767px) {
        display: none;
      }

    }

  }

  i {
    vertical-align: middle;
  }

}

.dropdown {
  display: block;
  margin: -20px;
  padding: 20px;

  @media (max-width: 1199px) {
    margin: -10px;
    padding: 10px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    width: 160px;
    z-index: 1;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }

  a {
    opacity: .6;

    &:hover,
    &:focus {
      background-color: $color-icon-hover;
      opacity: 1;
    }

  }

  &:hover .dropdown-content {
    display: block;
  }

}

</style>
