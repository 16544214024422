<template>
  <div>
    <h5>{{ item.question.title }}</h5>
    <div>
      {{ item.answer.value || item.answer }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        question: {},
        answer: {}
      })
    }
  }
}
</script>
