<template>
  <figure class="lookbook-figure">
    <img class="lookbook-figure__image" :src="thumbUrl" alt="">
    <span
      class="lookbook-figure__counter"
      :data-value="commentsCount"
    >
      {{ commentsCount }}
    </span>
    <span
      class="lookbook-figure__delete"
      title="Delete image"
      @click.stop="onDeleteClick"
    >
      <svg-icon class="lookbook-figure__icon" icon-class="trash" />
    </span>
  </figure>
</template>

<script>
import SvgIcon from '../SvgIcon'
import '../../icons/svg/trash.svg'

export default {
  components: {
    SvgIcon
  },

  props: {
    thumbUrl: {
      type: String,
      required: true
    },

    commentsCount: {
      type: Number,
      required: true
    }
  },

  methods: {
    onDeleteClick () {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss">
.lookbook-figure {
  margin: 0;
  width: 200px;
  height: 200px;
  position: relative;
  cursor: pointer;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__counter {
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 4px;
    min-width: 20px;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background: #888;

    &[data-value="0"] {
      background: #ccc;
    }
  }

  &__delete {
    position: absolute;
    right: 8px;
    bottom: 8px;
    opacity: 0;
    transition: opacity 0.2s;
    cursor: pointer;
  }

  &:hover > &__delete {
    opacity: 1;
  }

  &__icon {
    width: 24px;
    height: 24px;
    color: #666;
    fill: currentColor;

    &:hover {
      color: #000;
    }
  }
}
</style>
