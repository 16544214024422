<template>
  <div>
    <h5>{{ item.question.title }}</h5>
    <div class="uploaded-photo">
      <img class="uploaded-photo__image" :src="'https://couturme.com' + (item.answer.value || item.answer)" alt="">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        question: {},
        answer: {}
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../../../../css/animations/transitions';
  .uploaded-photo {
    max-width: 30%;
    @include hover-zoom(1.5)
  }
</style>
