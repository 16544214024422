<template>
  <div class="mb35">
    <!-- My newsletter header -->
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6" :class="{ 'cl-accent' : !isEdited }">
        <h3 class="m0 mb5">
          My newsletter
        </h3>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="lh30 flex end-md" v-if="!isEdited">
          <a href="#" class="cl-tertiary flex" @click.prevent="edit">
            <span class="pr5">
              Edit newsletter preferences
            </span>
            <i class="material-icons cl-tertiary">edit</i>
          </a>
        </div>
      </div>
    </div>

    <!-- My newsletter body (both modes) -->
    <div class="row">
      <div class="col-xs-12">
        <h4>
          General agreement
        </h4>
      </div>

      <base-checkbox
        class="col-xs-12 mb25"
        id="generalAgreement"
        v-model="user.isSubscribed"
        @click="isEdited ? user.isSubscribed = !user.isSubscribed : null"
        :disabled="!isEdited"
      >
        I want to receive a newsletter, and agree to its terms
      </base-checkbox>

      <div class="col-xs-12 col-sm-6" v-if="isEdited">
        <button-full @click.native="updateNewsletter">
          Update my preferences
        </button-full>
      </div>

      <div class="col-xs-12 col-sm-6 flex middle-xs py10" v-if="isEdited">
        <a href="#" @click="exitSection" class="h4 cl-accent">
          Cancel
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MyNewsletter',
  components: {},
  data () {
    return {
      isEdited: false,
      user: {
        isSubscribed: this.$store.state.user.current.isSubscribed
      }
    }
  },
  methods: {
    exitSection () {
      this.isEdited = false
    },
    edit () {
      this.isEdited = true
    },
    unsubscribe () {
      this.$store.dispatch('mailchimp/unsubscribe', this.$store.state.user.current.email).then(() => {
        this.user.isSubscribed = false
      }).catch(err =>
        this.$emit('unsubscription-error', err)
      )
    },
    subscribe () {
      this.$store.dispatch('mailchimp/subscribe', this.$store.state.user.current.email).then(() => {
        this.user.isSubscribed = true
      }).catch(err =>
        this.$emit('subscription-error', err)
      )
    },
    updateNewsletter () {
      if (this.user.isSubscribed) {
        this.subscribe()
      } else {
        this.unsubscribe()
      }
      this.$store.dispatch('user/update', { customer: { isSubscribed: this.isSubscribed } })
      this.exitSection()
    }
  }
}
</script>
