<template>
  <div class="my-questionnaire__wrapper">
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6 cl-accent">
        <h3 class="m0 mb5">
          My questionnaires
        </h3>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="lh30 flex end-md">
          <a href="#" class="cl-tertiary flex" @click="takeNewQuestionnaire">New questionnaire</a>
        </div>
      </div>
    </div>

    <div class="row mb15">
      <div class="col-xs-12">
        <div class="my-questionnaire__selector">
          <div class="my-questionnaire__selector__item" v-for="questionnaire in finishedQuestionnaires"
               @click="currentQuestionnaire = questionnaire" :key="questionnaire._id" :class="{'-selected': currentQuestionnaire === questionnaire}"
          >
            Questionnaire
            <span v-if="questionnaire.createdAt">
              passed at {{ (new Date(questionnaire.createdAt)).toLocaleString() }}.
            </span>
            <span v-if="questionnaire.payedAt">
              Payed at {{ (new Date(questionnaire.payedAt)).toLocaleString() }}
            </span>
            <div class="my-questionnaire__selector__item__load" @click="load(questionnaire)" v-if="!questionnaire.payedAt">
              Revisit
            </div>
          </div>
        </div>
      </div>
    </div>
    <component v-if="currentQuestionnaire" v-for="item in currentQuestionnaire.questionnaire" :item="item"
               :is="getComponentForQuestion(item)" :key="item.question.name"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import Common from './FieldsView/Common'
import UploadedImage from './FieldsView/UploadedImage'
import ImageSelection from './FieldsView/ImageSelection'
import FitQuestion from './FieldsView/FitQuestion'
import ConcealQuestion from './FieldsView/ConcealQuestion'
import FigureQuestion from './FieldsView/FigureQuestion'

export default {
  components: {
    Common,
    UploadedImage,
    ImageSelection,
    FitQuestion,
    ConcealQuestion,
    FigureQuestion
  },
  mounted () {
    this.getFinishedQuestionnaires(this.current)
  },
  data () {
    return {
      currentQuestionnaire: {}
    }
  },
  computed: {
    ...mapGetters('questionnaire', ['finishedQuestionnaires']),
    ...mapState('user', ['current'])
  },
  methods: {
    ...mapActions('questionnaire', ['getFinishedQuestionnaires']),
    ...mapMutations('questionnaire', ['reviewFinishedQuestionnaire', 'newQuestionnaire']),
    load (questionnaire) {
      this.reviewFinishedQuestionnaire(questionnaire)
      this.$router.push('/questionnaire')
    },
    takeNewQuestionnaire () {
      this.newQuestionnaire()
      this.$router.push('/questionnaire')
    },
    getComponentForQuestion (item) {
      switch (item.question.type) {
        case 'radiogroup':
        case 'textarea':
          return 'Common'
        case 'upload':
          return 'UploadedImage'
        case 'colors':
        case 'images':
        case 'fabrics':
          return 'ImageSelection'
        case 'custom':
          return item.question.componentName
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .my-questionnaire {
    &__new {
      box-sizing: border-box;
      padding: 5px 10px;
      border-radius: 3px;
      background-color: color(primary, $colors-theme, hover);
      color: color(primary, $colors-background);
    }
    &__wrapper {
      position: relative;
    }
    &__selector {
      &__item {
        cursor: pointer;
        box-sizing: border-box;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 3px;
        background-color: color(primary, $colors-background);
        color: color(primary, $colors-theme);
        &:hover {
          background-color: color(secondary, $colors-background, hover);
        }
        &.-selected {
          background-color: color(primary, $colors-background, hover);
        }
        &__load {
          float: right;
          display: inline-block;
          vertical-align: middle;
          box-sizing: border-box;
          padding: 5px 10px;
          border-radius: 3px;
          margin-top: -5px;
          background-color: color(primary, $colors-theme, hover);
          color: color(primary, $colors-background);
        }
      }
    }
  }
</style>
