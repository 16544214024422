<template>
  <div class="referral-settings">
    <div slot="header">
      <h2>Invite Friends, Earn Cash!</h2>
      <h4>Send a $5 credit to your friends, and earn even more for yourself when they join!</h4>
    </div>
    <div>
      <div v-if="info" class="referral-settings__info">
        You are currently have <span>{{ currentReferrals }} referrals</span>
      </div>
      <div class="slider">
        <div class="slider__base">
          <div class="slider__progress" :style="{width: progressPercent}" />
        </div>
        <div class="slider__info">
          <div class="slider__info__block">
            <span>Join</span>
          </div>
          <div class="slider__info__block">
            <span>Supporter</span>
            <span>5 Referrals</span>
            <span>Get $10</span>
          </div>
          <div class="slider__info__block">
            <span>Ambassador</span>
            <span>20 Referrals</span>
            <span>Get $30</span>
          </div>
          <div class="slider__info__block">
            <span>VIP</span>
            <span>50 Referrals</span>
            <span>Get $200</span>
          </div>
        </div>
      </div>
      <div v-if="referralUrl" class="referral-settings__link">
        <base-input :value="referralUrl" type="text" :focus="false" font-size=""
                    readonly placeholder="Copy and paste the url below into Twitter, Facebook or an Email."
        />
      </div>
      <div class="referral-settings__send">
        <base-input v-model="emailList" value="emailList" type="email" font-size=""
                    placeholder="Invite friends via Email:"
        />
        <button class="referral-settings__send__button" @click="sendReferralEmail">
          Invite
        </button>
        <div v-if="emailedList && emailedList.length" class="emailed-list">
          Thank you! An invitation email has been sent to: <span class="weight-400">{{ emailedList.join(', ') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      emailList: '',
      emailedList: []
    }
  },
  mounted () {
    this.updateInfo(true)
  },
  beforeDestroy () {
  },
  computed: {
    ...mapGetters({
      'info': 'referral/referralInfo'
    }),
    ...mapState('user', ['current']),
    currentReferrals () {
      if (this.info && (this.info.used || +this.info.used === 0)) {
        return +this.info.used
      }
      return 0
    },
    progressPercent () {
      return (this.currentReferrals <= 50 ? this.currentReferrals : 50) * 2 + '%'
    },
    baseUrl () {
      if (typeof window !== 'undefined') {
        return window.location.origin
      }
      return 'localhost'
    },
    referralUrl () {
      if (this.info) {
        return this.baseUrl + '/r/' + this.info.code
      }
      return ''
    }
  },
  methods: {
    ...mapActions('referral', ['requestInfo', 'sendEmail']),
    updateInfo (force = false) {
      if (this.current && (!this.info || force)) {
        this.requestInfo(this.current)
      }
    },
    sendReferralEmail () {
      const friends = this.emailList.split(/[\s,]+/)
      if (this.info && friends) {
        this.sendEmail({ user: this.current, friends }).then(list => {
          this.emailedList = list
          this.emailList = ''
        })
      }
    }
  },
  watch: {
    'user': 'updateInfo'
  }
}
</script>

<style lang="scss" scoped>
$slider-border: color(primary, $colors-border);
$color-white: color(primary, $colors-background);
$slider-progress: color(success, $colors-theme);
$white: color(white);

.question {
  &__single-button {
    width: 100%;
    display: block;
    padding: 20px 15%;
    border: 3px solid $slider-border;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    outline: none;
    margin-bottom: 10px;
  }
}
.emailed-list {
  margin: 20px 0;
  font-size: 14px;
}
.referral-settings {
  &__info {
    margin: 10px 0;
    font-size: 14px;
    span {
      font-weight: bold;
    }
  }
  &__link {
    margin: 40px 0;
    & > input[type=text] {
      font-size: 12px !important;
    }
  }
  &__send {
    position: relative;
    margin: 20px 0;
    &__button {
      position: absolute;
      right: 5px;
      top: 8px;
      border-radius: 3px;
      border: none;
      background-color: $slider-progress;
      color: $color-white;
      padding: 5px 10px;
      margin-bottom: 2px;
      box-sizing: border-box;
    }
  }
  .slider {
    &__base {
      height: 12px;
      border-radius: 2px;
      border: 1px solid $slider-border;
      padding: 1px;
      box-sizing: border-box;
    }

    &__progress {
      height: 8px;
      background-color: $slider-progress;
      min-width: 10%;
      transition: all 3s ease-out;
    }

    &__info {
      width: 100%;
      display: grid;
      grid-template-columns: 10% 10% 25% 55%;
      padding: 1px;
      box-sizing: border-box;
      margin-bottom: 20px;

      &__block {
        box-sizing: border-box;
        padding: 10px 10px 10px;
        text-align: right;
        font-size: 14px;
        border-right: 1px solid $slider-border;

        & > span {
          display: block;
          font-size: 12px;

          &:first-of-type {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
