<template>
  <div class="mb35">
    <!-- My profile header -->
    <div class="row mb15">
      <div class="col-xs-12 col-sm-6" :class="{ 'cl-accent' : !isEdited }">
        <h3 class="m0 mb5">
          My profile
        </h3>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="lh30 flex end-md" v-if="!isEdited">
          <a href="#" class="cl-tertiary flex" @click.prevent="edit">
            <span class="pr5">
              Edit your profile
            </span>
            <i class="material-icons cl-tertiary">edit</i>
          </a>
        </div>
      </div>
    </div>

    <!-- My profile body (edit mode) -->
    <div class="row" v-if="isEdited">
      <base-input
        class="col-xs-12 col-md-6 mb25"
        type="text"
        name="first-name"
        autocomplete="given-name"
        :placeholder="'First name'"
        v-model.trim="currentUser.firstname"
        @input="$v.currentUser.firstname.$touch()"
        :validations="[
          {
            condition: !$v.currentUser.firstname.required,
            text: 'Field is required'
          },
          {
            condition: !$v.currentUser.firstname.minLength,
            text: 'Name must have at least 3 letters.'
          }
        ]"
      />

      <base-input
        class="col-xs-12 col-md-6 mb25"
        type="text"
        name="last-name"
        autocomplete="family-name"
        :placeholder="'Last name'"
        v-model.trim="currentUser.lastname"
        @input="$v.currentUser.lastname.$touch()"
        :validation="{
          condition: !$v.currentUser.lastname.required,
          text: 'Field is required'
        }"
      />

      <base-input
        class="col-xs-12 col-md-6 mb25"
        type="email"
        name="email-address"
        autocomplete="email"
        :placeholder="'Email address'"
        v-model="currentUser.email"
        :validations="[
          {
            condition: !$v.currentUser.email.required,
            text: 'Field is required'
          },
          {
            condition: !$v.currentUser.email.email,
            text: 'Please provide valid e-mail address.'
          }
        ]"
      />

      <!-- Change password (edit mode) -->
      <base-checkbox
        class="col-xs-12 mb15"
        id="changePassword"
        v-model="changePassword"
        @click="changePassword = !changePassword"
      >
        Change my password
      </base-checkbox>

      <template v-if="changePassword">
        <base-input
          class="col-xs-12 col-md-6 mb15 mt10"
          type="password"
          name="old-password"
          autocomplete="current-password"
          :placeholder="'Current password *'"
          v-model="oldPassword"
          @input="$v.oldPassword.$touch()"
          :validation="{
            condition: !$v.oldPassword.required && $v.oldPassword.$error,
            text: 'Field is required'
          }"
        />

        <div class="hidden-xs hidden-sm col-md-6 mb15 mt10" />

        <base-input
          class="col-xs-12 col-md-6 mb15 mt10"
          type="password"
          name="password"
          autocomplete="new-password"
          :placeholder="'New password *'"
          v-model="password"
          @input="$v.password.$touch()"
          :validation="{
            condition: !$v.password.required && $v.password.$error,
            text: 'Field is required'
          }"
        />

        <base-input
          class="col-xs-12 col-md-6 mb15 mt10"
          type="password"
          name="password-confirm"
          autocomplete="new-password"
          :placeholder="'Repeat new password *'"
          v-model="rPassword"
          @input="$v.rPassword.$touch()"
          :validations="[
            {
              condition: !$v.rPassword.required && $v.rPassword.$error,
              text: 'Field is required'
            },
            {
              condition: !$v.rPassword.sameAsPassword,
              text: 'Passwords must be identical.'
            }
          ]"
        />
      </template>

      <!-- Company information (edit mode) -->
      <!--<base-checkbox
        class="col-xs-12 mb15 mt10"
        id="addCompany"
        v-model="addCompany"
        @click="addCompany = !addCompany"
      >
        I have a company and want to receive an invoice for every order
      </base-checkbox>-->

      <template v-if="addCompany">
        <base-input
          class="col-xs-12 mb25"
          type="text"
          name="company-name"
          autocomplete="organization"
          :placeholder="'Company name *'"
          v-model.trim="userCompany.company"
          @input="$v.userCompany.company.$touch()"
          :validation="{
            condition: !$v.userCompany.company.required && $v.userCompany.company.$error,
            text: 'Field is required'
          }"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="street-address"
          autocomplete="address-line1"
          :placeholder="'Street name *'"
          v-model.trim="userCompany.street"
          @input="$v.userCompany.street.$touch()"
          :validation="{
            condition: !$v.userCompany.street.required && $v.userCompany.street.$error,
            text: 'Field is required'
          }"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="apartment-number"
          autocomplete="address-line2"
          :placeholder="'House/Apartment number *'"
          v-model.trim="userCompany.house"
          @input="$v.userCompany.house.$touch()"
          :validation="{
            condition: !$v.userCompany.house.required && $v.userCompany.house.$error,
            text: 'Field is required'
          }"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="city"
          autocomplete="address-level2"
          :placeholder="'City *'"
          v-model.trim="userCompany.city"
          @input="$v.userCompany.city.$touch()"
          :validation="{
            condition: !$v.userCompany.city.required && $v.userCompany.city.$error,
            text: 'Field is required'
          }"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="state"
          autocomplete="address-level1"
          :placeholder="'State / Province'"
          v-model.trim="userCompany.region"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="zip-code"
          autocomplete="postal-code"
          :placeholder="'Zip code *'"
          v-model.trim="userCompany.postcode"
          @input="$v.userCompany.postcode.$touch()"
          :validations="[
            {
              condition: !$v.userCompany.postcode.required && $v.userCompany.postcode.$error,
              text: 'Field is required'
            },
            {
              condition: !$v.userCompany.postcode.minLength,
              text: 'Zip code must have at least 3 letters.'
            }
          ]"
        />

        <base-select
          class="col-xs-12 col-md-6 mb25"
          name="countries"
          :options="countryOptions"
          :selected="userCompany.country"
          :placeholder="'Country *'"
          :validations="[
            {
              condition: $v.userCompany.country.$error && !$v.userCompany.country.required,
              text: 'Field is required'
            }
          ]"
          v-model="userCompany.country"
          autocomplete="country-name"
          @blur="$v.userCompany.country.$touch()"
          @change="$v.userCompany.country.$touch()"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="taxId"
          autocomplete="tax-id"
          :placeholder="'Tax ID *'"
          v-model.trim="userCompany.taxId"
          @input="$v.userCompany.taxId.$touch()"
          :validations="[
            {
              condition: !$v.userCompany.taxId.required && $v.userCompany.taxId.$error,
              text: 'Field is required'
            },
            {
              condition: !$v.userCompany.taxId.minLength,
              text: 'Tax ID must have at least 3 letters.'
            }
          ]"
        />

        <base-input
          class="col-xs-12 col-sm-6 mb25"
          type="text"
          name="phone-number"
          autocomplete="tel"
          :placeholder="'Phone Number'"
          v-model.trim="userCompany.phone"
        />
      </template>

      <div class="col-xs-12 col-sm-6">
        <button-full
          @click.native="updateProfile"
          :disabled="checkValidation()"
        >
          Update my profile
        </button-full>
      </div>
      <div class="col-xs-12 col-sm-6 flex middle-xs py10">
        <a href="#" @click="exitSection" class="h4 cl-accent">
          Cancel
        </a>
      </div>
    </div>

    <!-- My profile summary -->
    <div class="row fs16 mb35" v-else>
      <div class="col-xs-12 h4">
        <p>
          {{ currentUser.firstname }} {{ currentUser.lastname }}
        </p>
        <p>
          {{ currentUser.email }}
        </p>
        <base-checkbox
          v-if="addCompany"
          class="mb25"
          id="addCompanyFilled"
          v-model="addCompany"
          disabled
        >
          I have a company and want to receive an invoice for every order
        </base-checkbox>
        <template v-if="addCompany">
          <p class="mb25">
            {{ userCompany.company }}
          </p>
          <p class="mb25">
            {{ userCompany.street }}
            <span v-if="userCompany.house">
              {{ userCompany.house }}
            </span>
          </p>
          <p class="mb25">
            {{ userCompany.city }} {{ userCompany.postcode }}
          </p>
          <p class="mb25">
            <span v-if="userCompany.region">{{ userCompany.region }}, </span>
            <span>
              {{ getCountryName() }}
            </span>
          </p>
          <p class="mb25" v-if="userCompany.taxId">
            {{ userCompany.taxId }}
          </p>
          <div class="mb25">
            {{ userCompany.phone }}
            <tooltip v-if="userCompany.phone">
              Phone number may be needed by carrier
            </tooltip>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import Countries from '../../../resource/i18n/countries.json'
import Tooltip from '../../Tooltip'
import { EVENT_USER_PROFILE_LOADED } from '@/store/modules/user/store'

export default {
  name: 'MyProfile',
  components: {
    Tooltip
  },
  validations: {
    currentUser: {
      firstname: {
        required,
        minLength: minLength(3)
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      }
    },
    oldPassword: {
      required
    },
    password: {
      required
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
    userCompany: {
      company: {
        required
      },
      country: {
        required
      },
      street: {
        required
      },
      house: {
        required
      },
      postcode: {
        required,
        minLength: minLength(5)
      },
      city: {
        required
      },
      taxId: {
        required,
        minLength: minLength(3)
      }
    }
  },
  data () {
    return {
      currentUser: Object.assign({}, this.$store.state.user.current),
      userCompany: {
        company: '',
        street: '',
        house: '',
        city: '',
        region: '',
        country: '',
        postcode: '',
        taxId: '',
        phone: ''
      },
      countries: Countries,
      changePassword: false,
      oldPassword: '',
      password: '',
      rPassword: '',
      addCompany: false,
      isEdited: false,
      remainInEditMode: false
    }
  },
  computed: {
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    }
  },
  beforeMount () {
    this.$bus.$on(EVENT_USER_PROFILE_LOADED, this.onLoggedIn)
    this.$bus.$on('myAccount-before-remainInEditMode', block => {
      if (block === 'MyProfile') {
        this.remainInEditMode = true
      }
    })
    if (this.$route.hash && this.$route.hash.includes('edit')) {
      this.edit()
    }
  },
  beforeDestroy () {
    this.$bus.$off(EVENT_USER_PROFILE_LOADED, this.onLoggedIn)
    this.$bus.$off('myAccount-before-remainInEditMode')
  },
  mounted () {
    this.userCompany = this.getUserCompany()
    if (this.userCompany.company) {
      this.addCompany = true
    }
  },
  methods: {
    checkValidation () {
      if (this.changePassword && this.addCompany) {
        return this.$v.$invalid
      } else if (this.changePassword && !this.addCompany) {
        return this.$v.currentUser.$invalid || this.$v.password.$invalid || this.$v.rPassword.$invalid
      } else if (!this.changePassword && this.addCompany) {
        return this.$v.currentUser.$invalid || this.$v.userCompany.$invalid
      } else {
        return this.$v.currentUser.$invalid
      }
    },
    onLoggedIn () {
      this.currentUser = Object.assign({}, this.$store.state.user.current)
      this.userCompany = this.getUserCompany()
      if (this.userCompany.company) {
        this.addCompany = true
      }
    },
    edit () {
      this.isEdited = true
    },
    objectsEqual (a, b, excludedFields = []) {
      const aProps = Object.keys(a)
      const bProps = Object.keys(b)

      if (aProps.length !== bProps.length) {
        return false
      }

      for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i]
        if (!b.hasOwnProperty(propName)) {
          return false
        } else {
          if (a[propName] !== null && b[propName] !== null && a[propName] === 'object' && b[propName] === 'object') {
            if (!this.objectsEqual(a[propName], b[propName])) {
              return false
            }
          } else if (!excludedFields.includes(propName) && a[propName] !== b[propName]) {
            return false
          }
        }
      }
      return true
    },
    updateProfile () {
      let updatedProfile
      if (!this.objectsEqual(this.currentUser, this.$store.state.user.current, ['updated_at', 'addresses']) ||
        !this.objectsEqual(this.userCompany, this.getUserCompany()) ||
        (this.userCompany.company && !this.addCompany)
      ) {
        updatedProfile = JSON.parse(JSON.stringify(this.$store.state.user.current))
        updatedProfile.firstname = this.currentUser.firstname
        updatedProfile.lastname = this.currentUser.lastname
        updatedProfile.email = this.currentUser.email
        if (updatedProfile.hasOwnProperty('default_billing')) {
          let index
          for (let i = 0; i < updatedProfile.addresses.length; i++) {
            if (updatedProfile.addresses[i].id === Number(updatedProfile.default_billing)) {
              index = i
            }
          }
          if (index >= 0) {
            if (this.addCompany) {
              updatedProfile.addresses[index].firstname = this.currentUser.firstname || ''
              updatedProfile.addresses[index].lastname = this.currentUser.lastname || ''
              updatedProfile.addresses[index].company = this.userCompany.company || ''
              updatedProfile.addresses[index].street = [this.userCompany.street, this.userCompany.house] || ['', '']
              updatedProfile.addresses[index].city = this.userCompany.city || ''
              updatedProfile.addresses[index].region = {
                region: this.userCompany.region ? this.userCompany.region : null
              }
              updatedProfile.addresses[index].country_id = this.userCompany.country || ''
              updatedProfile.addresses[index].postcode = this.userCompany.postcode || ''
              updatedProfile.addresses[index].vat_id = this.userCompany.taxId || ''
              updatedProfile.addresses[index].telephone = this.userCompany.phone || ''
            } else {
              updatedProfile.addresses.splice(index, 1)
              this.userCompany = {
                company: '',
                street: '',
                house: '',
                city: '',
                region: '',
                country: '',
                postcode: '',
                taxId: '',
                phone: ''
              }
            }
          }
        } else if (this.addCompany) {
          updatedProfile.addresses.push({
            firstname: this.currentUser.firstname,
            lastname: this.currentUser.lastname,
            company: this.userCompany.company,
            street: [this.userCompany.street, this.userCompany.house],
            city: this.userCompany.city,
            ...(this.userCompany.region ? { region: { region: this.userCompany.region } } : {}),
            country_id: this.userCompany.country,
            postcode: this.userCompany.postcode,
            vat_id: this.userCompany.taxId,
            telephone: this.userCompany.phone,
            default_billing: true
          })
        }
      }
      if (this.password) {
        this.$bus.$emit('myAccount-before-changePassword', {
          currentPassword: this.oldPassword,
          newPassword: this.password
        })
      }
      this.exitSection(null, updatedProfile)
    },
    exitSection (event, updatedProfile) {
      this.$bus.$emit('myAccount-before-updateUser', updatedProfile)
      if (!updatedProfile) {
        this.currentUser = Object.assign({}, this.$store.state.user.current)
        this.userCompany = this.getUserCompany()
        this.changePassword = false
        this.oldPassword = ''
        this.password = ''
        this.rPassword = ''
        if (!this.userCompany.company) {
          this.addCompany = false
        }
        this.remainInEditMode = false
      }
      if (!this.remainInEditMode) {
        this.isEdited = false
      }
    },
    getUserCompany () {
      let user = this.$store.state.user.current
      if (user.hasOwnProperty('default_billing')) {
        let index
        for (let i = 0; i < this.currentUser.addresses.length; i++) {
          if (user.addresses[i].id === Number(user.default_billing)) {
            index = i
          }
        }
        if (index >= 0) {
          return {
            company: user.addresses[index].company || '',
            street: user.addresses[index].street[0] || '',
            house: user.addresses[index].street[1] || '',
            city: user.addresses[index].city || '',
            region: user.addresses[index].region.region ? user.addresses[index].region.region : '',
            country: user.addresses[index].country_id || '',
            postcode: user.addresses[index].postcode || '',
            taxId: user.addresses[index].vat_id || '',
            phone: user.addresses[index].telephone || ''
          }
        }
      } else {
        return {
          company: '',
          street: '',
          house: '',
          city: '',
          region: '',
          country: '',
          postcode: '',
          taxId: '',
          phone: ''
        }
      }
    },
    getCountryName () {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].code === this.userCompany.country) {
          return this.countries[i].name
        }
      }
      return ''
    }
  }

}
</script>
